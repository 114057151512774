<template>
  <eagle-list
    :list-key="listKey"
  ></eagle-list>
</template>

<script>
import listMixin from '@/components/list/mixins/listMixin'
import listConfig from './siteAdminDeviceListConfig'
export default {
  mixins: [listMixin],
  data: () => ({
    listKey: 'site-admin-device-list',
    bindRoute: false,
    meta: {},
  }),
  methods: {
    async beforeIndex() {
      await Promise.all([])
    },
    async indexApi(params) {
      return await this.$api.collection.siteAdminDeviceApi.index(params)
    },
    async deleteApi(target) {
      return await this.$api.collection.siteAdminDeviceApi.delete(target)
    },
    getListConfig() {
      return listConfig
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
