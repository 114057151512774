import Vue from 'vue'
import { EagleListConfigInterface, TableDataType } from '@/components/list/types/list'
class listConfig {
  protected vm?: Vue
  protected listKey?: string

  get(vm: Vue, listKey: string) : EagleListConfigInterface {
    this.vm = vm
    this.listKey = listKey

    return {
      hasWritePermission: (hasRole) : boolean => hasRole(['ROLE_ADMIN']),
      search: 'site_admin_device.search_keyword',
      filter: {
        created_at: {
          label: 'data.created_at',
          type: 'date',
        },
      },
      displayMode: 'table',
      sort: [
        { label: 'data.created_at', key: 'created_at' },
        { label: 'site_admin_device.data.fingerprint', key: 'fingerprint' },
        { label: 'site_admin_device.data.type', key: 'type' },
        { label: 'site_admin_device.data.browser', key: 'browser' },
        { label: 'site_admin_device.data.os', key: 'os' },
        { label: 'data.ip', key: 'ip' },
      ],
      table: {
        data: <TableDataType>[
          {
            key: 'fingerprint',
            copyable: true,
            label: 'site_admin_device.data.fingerprint',
            type: 'text',
            help: 'site_admin_device.data.fingerprint.help',
          },
          {
            key: 'type',
            label: 'site_admin_device.data.type',
            type: 'text',
            text: row => this.vm?.$t(`site_admin_device.data.type.${row.type}`),
            tooltip: false,
          },
          {
            key: 'os',
            label: 'site_admin_device.data.os',
            type: 'text',
          },
          {
            key: 'browser',
            label: 'site_admin_device.data.browser',
            type: 'text',
          },
          {
            key: 'ip',
            label: 'data.ip',
            copyable: true,
            type: 'text',
          },
          {
            key: 'created_at',
            label: 'data.created_at',
            type: 'time',
          },
        ],
      },
      dataAction: {
        delete: {
          label: 'site_admin_group.action.logout',
          buttonProperties: () => ({
            class: 'orange',
          }),
          confirmTitle: row => this.vm?.$t('site_admin_group.confirm.logout'),
          removeTarget: row => `${this.vm?.$t('site_admin_device.data.fingerprint')}: ${row.fingerprint}`,
        },
      },
    }

  }
}

export default new listConfig()
